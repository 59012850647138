import React from "react";
import ExternalLinkButton from "../atoms/ExternalLinkButton";

const ExternalLinks = ({ linkedinUrl, memberProfileUrl }) => (
  <div className="w-full grid grid-cols-2 gap-3 sm:gap-4">
    <ExternalLinkButton
      text="LinkedIn Profile"
      color="bg-blue-500"
      url={linkedinUrl}
      className="w-full"
    />
    <ExternalLinkButton
      text="Member Profile"
      color="bg-purple-600 hover:bg-purple-700"
      url={memberProfileUrl}
      className="w-full"
    />
  </div>
);

export default ExternalLinks;