import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const GuestHeader = ({ name, location, careerStage, imageUrl }) => (
  <div className="flex items-center">
    <Avatar className="h-16 w-16 mr-4">
      <AvatarImage src={imageUrl} alt={name} />
      <AvatarFallback>{name.charAt(0)}</AvatarFallback>
    </Avatar>
    <div>
      <h2 className="text-2xl font-semibold">{name}</h2>
      <p className="text-gray-600">{location}</p>
      <p className="text-gray-600">{careerStage}</p>
    </div>
  </div>
);

export default GuestHeader;