import React from "react";
import { TableCell, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Pencil, X, Check } from "lucide-react";

const ListItem = React.memo(
  ({
    item,
    index,
    isEditing,
    isSelected,
    onSelect,
    onEdit,
    onCancel,
    onApprove,
  }) => {
    const [editValue, setEditValue] = React.useState(item);

    React.useEffect(() => {
      setEditValue(item);
    }, [item]);

    return (
      <TableRow>
        {!isEditing && (
          <TableCell>
            <Checkbox
              checked={isSelected}
              onCheckedChange={() => onSelect(index)}
            />
          </TableCell>
        )}
        <TableCell colSpan={isEditing ? 3 : 1}>
          {isEditing ? (
            <Textarea
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              className="w-full"
              rows={3}
            />
          ) : (
            item
          )}
        </TableCell>
        {!isEditing && (
          <TableCell className="text-right">
            <div className="flex justify-end space-x-2">
              <Button variant="ghost" size="icon" onClick={onEdit}>
                <Pencil className="h-4 w-4" />
              </Button>
            </div>
          </TableCell>
        )}
        {isEditing && (
          <TableCell className="text-right">
            <div className="flex justify-end space-x-2">
              <Button variant="ghost" size="icon" onClick={onCancel}>
                <X className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => onApprove(editValue)}
              >
                <Check className="h-4 w-4" />
              </Button>
            </div>
          </TableCell>
        )}
      </TableRow>
    );
  }
);

export default ListItem;
