import React from "react";
import ReactMarkdown from "react-markdown";
import HostHeader from "../molecules/HostHeader";
import MeetingInfo from "../molecules/MeetingInfo";
import { Card, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";

const MarkdownStyles = {
  p: "mb-2 sm:mb-4 text-sm sm:text-base",
  a: "text-blue-500 hover:underline",
  ul: "list-disc pl-4 sm:pl-5 mb-2 sm:mb-4 text-sm sm:text-base",
  ol: "list-decimal pl-4 sm:pl-5 mb-2 sm:mb-4 text-sm sm:text-base",
  li: "mb-1 sm:mb-2",
};

const UpcomingGuestContent = ({
  name,
  location,
  careerStage,
  imageUrl,
  loopDate,
  meetingUrl,
  briefing,
  rescheduleOrCancelUrl,
}) => (
  <div className="bg-white rounded-lg shadow-md max-h-full overflow-hidden flex flex-col">
    <HostHeader
      name={name}
      location={location}
      careerStage={careerStage}
      imageUrl={imageUrl}
    />
    <div className="p-3 sm:p-6 flex-grow overflow-auto">
      <MeetingInfo
        loopDate={loopDate}
        meetingUrl={meetingUrl}
        rescheduleOrCancelUrl={rescheduleOrCancelUrl}
      />
      <Card className="mt-4 sm:mt-6">
        <ScrollArea className="h-[45vh]">
          <CardContent className="pt-4 sm:pt-6">
            <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4">
              Briefing
            </h3>
            <div className="prose max-w-none text-sm sm:text-base">
              <ReactMarkdown
                components={{
                  p: ({ node, ...props }) => (
                    <p className={MarkdownStyles.p} {...props} />
                  ),
                  a: ({ node, ...props }) => (
                    <a className={MarkdownStyles.a} {...props} />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul className={MarkdownStyles.ul} {...props} />
                  ),
                  ol: ({ node, ...props }) => (
                    <ol className={MarkdownStyles.ol} {...props} />
                  ),
                  li: ({ node, ...props }) => (
                    <li className={MarkdownStyles.li} {...props} />
                  ),
                }}
              >
                {briefing || "No briefing available"}
              </ReactMarkdown>
            </div>
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  </div>
);

export default UpcomingGuestContent;
