import React, { useState } from "react";
import { differenceInDays } from "date-fns";
import { useMeetings, useLinkedin } from "../../integrations/supabase";
import { useMeetingsContext } from "../../contexts/MeetingsContext";
import LastCallsSection from "../molecules/LastCallsSection";
import GuestProfileCard from "../molecules/GuestProfileCard";
import MatchesSection from "../molecules/MatchesSection";
import SpotlightPostModal from "./SpotlightPostModal";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import GuestHeader from "../molecules/GuestHeader";
import StatSection from "../molecules/StatSection";
import ActionButtons from "../molecules/ActionButtons";

const CompletedGuestContent = ({
  name,
  location,
  careerStage,
  imageUrl,
  matches,
  interests,
  keySkills,
  businessGoals,
  guestUserId,
}) => {
  const { useGetMeetings } = useMeetings();
  const { useGetLinkedinProfileByUserId } = useLinkedin();
  const { data: guestMeetings, isLoading: isGuestMeetingsLoading } =
    useGetMeetings(guestUserId);
  const { data: linkedinProfile, isLoading: isLinkedinProfileLoading } =
    useGetLinkedinProfileByUserId(guestUserId);
  const [isSpotlightModalOpen, setIsSpotlightModalOpen] = useState(false);
  const { completedMeetings } = useMeetingsContext();
  const { session } = useSupabaseAuth();

  const isStatsLoading = isGuestMeetingsLoading || isLinkedinProfileLoading;

  const daysSinceRegistration =
    !isStatsLoading && guestMeetings && guestMeetings.length > 0
      ? differenceInDays(new Date(), new Date(guestMeetings[0].created_at))
      : null;

  const lastCallIn =
    !isStatsLoading && guestMeetings && guestMeetings.length > 0
      ? differenceInDays(
          new Date(),
          new Date(guestMeetings[guestMeetings.length - 1].event_start_time)
        )
      : null;

  const linkedInUpdated =
    !isStatsLoading && linkedinProfile?.updated_at
      ? `${differenceInDays(
          new Date(),
          new Date(linkedinProfile.updated_at)
        )} days ago`
      : null;

  const handleSpotlightGenerated = () => {
    setIsSpotlightModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSpotlightModalOpen(false);
  };

  // Filter completed meetings
  const completedGuestMeetings = !isGuestMeetingsLoading
    ? guestMeetings.filter((meeting) => meeting.meeting_status === "done")
    : [];

  const lastMeetingId =
    completedGuestMeetings.length > 0
      ? completedGuestMeetings[completedGuestMeetings.length - 1].meeting_id
      : "";

  return (
    <div className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col">
      <div className="flex justify-between items-start mb-6">
        <GuestHeader
          name={name}
          location={location}
          careerStage={careerStage}
          imageUrl={imageUrl}
        />
        <div className="flex space-x-4">
          <ActionButtons
            guestUserId={guestUserId}
            meetingId={lastMeetingId}
            hostId={session?.user?.id}
            onSpotlightGenerated={handleSpotlightGenerated}
          />
          <StatSection
            isStatsLoading={isStatsLoading}
            daysSinceRegistration={daysSinceRegistration}
            lastCallIn={lastCallIn}
            linkedInUpdated={linkedInUpdated}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 flex-grow">
        <LastCallsSection guestMeetings={completedGuestMeetings} />
        <GuestProfileCard
          keySkills={keySkills}
          businessGoals={businessGoals}
          interests={interests}
        />
        <MatchesSection matches={matches} />
      </div>
      <SpotlightPostModal
        isOpen={isSpotlightModalOpen}
        onClose={handleCloseModal}
        guestName={name}
        hostEmail={completedMeetings[completedMeetings.length - 1]?.host_email}
      />
    </div>
  );
};

export default CompletedGuestContent;
