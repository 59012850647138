import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";

/*
### matches

| name                        | type                     | format | required |
|-----------------------------|--------------------------|--------|----------|
| match_id                    | uuid                     | string | true     |
| user_id                     | uuid                     | string | true     |
| matched_user_id             | uuid                     | string | true     |
| matching_score              | numeric                  | number | false    |
| explanation                 | text                     | string | false    |
| complementary_skills        | text[]                   | array  | false    |
| potential_collaboration     | text                     | string | false    |
| shared_interests            | text[]                   | array  | false    |
| geographical_synergy        | text                     | string | false    |
| experience_level            | text                     | string | false    |
| communication_compatibility | text                     | string | false    |
| created_at                  | timestamp with time zone | string | false    |
| updated_at                  | timestamp with time zone | string | false    |

Foreign Key Relationships:
- user_id references profiles.user_id
- matched_user_id references profiles.user_id
*/

export const useMatches = () => {
  const queryClient = useQueryClient();

  const getMatches = async (userId) => {
    const { data, error } = await supabase
      .from("matches")
      .select(
        `
        *,
        matched_user:profiles!matched_user_id(
          user_id,
          name,
          image_url,
          key_skills,
          industry,
          business_goals,
          interests,
          location,
          hobbies,
          career_stage,
          preferred_communication,
          link_linkedin,
          link_aaa
        )
      `
      )
      .eq("user_id", userId)
      .order('matching_score', { ascending: false });
    if (error) throw error;
    return data;
  };

  const addMatch = async (newMatch) => {
    const { data, error } = await supabase.from("matches").insert([newMatch]);
    if (error) throw error;
    return data;
  };

  const updateMatch = async ({ match_id, updatedMatch }) => {
    const { data, error } = await supabase
      .from("matches")
      .update(updatedMatch)
      .eq("match_id", match_id);
    if (error) throw error;
    return data;
  };

  const deleteMatch = async (match_id) => {
    const { data, error } = await supabase
      .from("matches")
      .delete()
      .eq("match_id", match_id);
    if (error) throw error;
    return data;
  };

  return {
    useGetMatches: (userId) =>
      useQuery({
        queryKey: ["matches", userId],
        queryFn: () => getMatches(userId),
        enabled: !!userId,
      }),
    useAddMatch: () =>
      useMutation({
        mutationFn: addMatch,
        onSuccess: () => queryClient.invalidateQueries(["matches"]),
      }),
    useUpdateMatch: () =>
      useMutation({
        mutationFn: updateMatch,
        onSuccess: () => queryClient.invalidateQueries(["matches"]),
      }),
    useDeleteMatch: () =>
      useMutation({
        mutationFn: deleteMatch,
        onSuccess: () => queryClient.invalidateQueries(["matches"]),
      }),
  };
};
