import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";
import { useEffect } from "react";

/*
### profiles

| name                    | type                     | format | required |
|-------------------------|--------------------------|--------|----------|
| user_id                 | uuid                     | string | true     |
| name                    | text                     | string | false    |
| image_url               | text                     | string | false    |
| key_skills              | text[]                   | array  | false    |
| industry                | text                     | string | false    |
| business_goals          | text[]                   | array  | false    |
| interests               | text[]                   | array  | false    |
| location                | text                     | string | false    |
| hobbies                 | text[]                   | array  | false    |
| career_stage            | text                     | string | false    |
| preferred_communication | text                     | string | false    |
| link_linkedin           | text                     | string | false    |
| link_aaa                | text                     | string | false    |
| created_at              | timestamp with time zone | string | false    |
| updated_at              | timestamp with time zone | string | false    |
| user_edits              | boolean                  | boolean | false    |

Foreign Key Relationships:
- user_id references auth.users.id

*/

export const useProfiles = () => {
  const queryClient = useQueryClient();

  const getUserProfile = async (userId) => {
    if (!userId) throw new Error("User ID is required");
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("user_id", userId)
      .single();
    if (error) throw error;
    return data;
  };

  const addProfile = async (newProfile) => {
    const { data, error } = await supabase
      .from("profiles")
      .insert([newProfile]);
    if (error) throw error;
    return data;
  };

  const updateProfile = async ({ user_id, updatedProfile }) => {
    const { data, error } = await supabase
      .from("profiles")
      .update(updatedProfile)
      .eq("user_id", user_id);
    if (error) throw error;
    return data;
  };

  const deleteProfile = async (user_id) => {
    const { data, error } = await supabase
      .from("profiles")
      .delete()
      .eq("user_id", user_id);
    if (error) throw error;
    return data;
  };

  const deleteProfileItems = async ({ user_id, listType, items }) => {
    const { data, error } = await supabase
      .from("profiles")
      .update({ [listType]: items, user_edits: true })
      .eq("user_id", user_id);
    if (error) throw error;
    return data;
  };

  const useGetUserProfile = (userId) => {
    const query = useQuery({
      queryKey: ["profile", userId],
      queryFn: () => getUserProfile(userId),
      enabled: !!userId,
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    });

    useEffect(() => {
      if (!userId) return;

      const subscription = supabase
        .channel(`profile:${userId}`)
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "profiles",
            filter: `user_id=eq.${userId}`,
          },
          (payload) => {
            queryClient.invalidateQueries(["profile", userId]);
          }
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "profiles",
            filter: `user_id=eq.${userId}`,
          },
          (payload) => {
            queryClient.invalidateQueries(["profile", userId]);
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(subscription);
      };
    }, [userId, queryClient]);

    return query;
  };

  return {
    useGetUserProfile,
    useAddProfile: () =>
      useMutation({
        mutationFn: addProfile,
        onSuccess: () => queryClient.invalidateQueries(["profile"]),
        onError: (error) => {
          console.error("Error adding profile:", error);
        },
      }),
    useUpdateProfile: () =>
      useMutation({
        mutationFn: updateProfile,
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries(["profile", variables.user_id]),
        onError: (error) => {
          console.error("Error updating profile:", error);
        },
      }),
    useDeleteProfile: () =>
      useMutation({
        mutationFn: deleteProfile,
        onSuccess: (_, user_id) =>
          queryClient.invalidateQueries(["profile", user_id]),
        onError: (error) => {
          console.error("Error deleting profile:", error);
        },
      }),
    useDeleteProfileItems: () =>
      useMutation({
        mutationFn: deleteProfileItems,
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries(["profile", variables.user_id]),
        onError: (error) => {
          console.error("Error deleting profile items:", error);
        },
      }),
  };
};