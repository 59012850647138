import React from "react";
import ExpandedSectionItem from "../atoms/ExpandedSectionItem";
import { Zap, Briefcase, Users, Heart, MessageSquare, Globe } from "lucide-react";

const ExpandedSection = ({
  matchReason,
  potentialCollaboration,
  complimentarySkills,
  sharedInterests,
  communicationCompatibility,
  geographicalSynergy,
}) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
    <ExpandedSectionItem
      icon={<Zap />}
      title="Match Reason"
      content={matchReason}
    />
    <ExpandedSectionItem
      icon={<Briefcase />}
      title="Potential Collaboration"
      content={potentialCollaboration}
    />
    <ExpandedSectionItem
      icon={<Users />}
      title="Complementary Skills"
      content={complimentarySkills}
    />
    <ExpandedSectionItem
      icon={<Heart />}
      title="Shared Interests"
      content={sharedInterests}
    />
    <ExpandedSectionItem
      icon={<MessageSquare />}
      title="Communication Compatibility"
      content={communicationCompatibility}
    />
    <ExpandedSectionItem
      icon={<Globe />}
      title="Geographical Synergy"
      content={geographicalSynergy}
    />
  </div>
);

export default ExpandedSection;