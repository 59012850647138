import React from 'react';
import InfoItem from '../atoms/InfoItem';
import { Button } from "@/components/ui/button";

const MeetingInfo = ({ loopDate, meetingUrl, rescheduleOrCancelUrl }) => (
  <div>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-2 sm:mb-4">
      <InfoItem label="Meeting Date" value={loopDate} />
      <div>
        <p className="font-semibold text-sm sm:text-base">Meeting Link:</p>
        <a
          href={meetingUrl}
          className="text-blue-500 hover:underline text-sm sm:text-base"
          target="_blank"
          rel="noopener noreferrer"
        >
          {meetingUrl !== "#" ? "Join Meeting" : "Link Not Available"}
        </a>
      </div>
    </div>
    {rescheduleOrCancelUrl && (
      <Button
        className="mb-4 sm:mb-6 text-sm sm:text-base"
        onClick={() => window.open(rescheduleOrCancelUrl, '_blank')}
      >
        Reschedule or Cancel
      </Button>
    )}
  </div>
);

export default MeetingInfo;
