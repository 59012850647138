import React, { useState } from "react";
import { useProfiles } from "../../integrations/supabase";
import { Skeleton } from "@/components/ui/skeleton";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import ProfileHeader from "../molecules/ProfileHeader";
import ProfileInfo from "../molecules/ProfileInfo";
import ProfileLists from "../molecules/ProfileLists";
import ContractibleList from "../atoms/ContractibleList";
import PreferredCommunication from "../molecules/PreferredCommunication";
import { Button } from "@/components/ui/button";
import { Pencil, Check, Replace } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "sonner";
import axios from "axios";
import { isEqual } from "lodash";
import { CreateableCombobox } from "../molecules/CreateableCombobox/CreateableCombobox";

const ProfileCard = () => {
  const { session } = useSupabaseAuth();
  const { useGetUserProfile, useUpdateProfile } = useProfiles();
  const {
    data: profile,
    isLoading,
    error,
  } = useGetUserProfile(session?.user?.id);
  const updateProfile = useUpdateProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImagePreview, setNewImagePreview] = useState(null);

  if (error) {
    console.error("Error in ProfileCard:", error);
    return (
      <div className="text-red-500">Error loading profile: {error.message}</div>
    );
  }

  if (isLoading || !profile) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-24 w-24 rounded-full" />
        <Skeleton className="h-8 w-3/4" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-2/3" />
      </div>
    );
  }

  const toggleEdit = () => {
    if ((isEditing && !isEqual(profile, editedProfile)) || newImageFile) {
      handleUpdateProfile(editedProfile);
    }
    setIsEditing(!isEditing);
    setEditedProfile(profile);
  };

  const handleInputChange = (field, value) => {
    setEditedProfile((prev) => ({ ...prev, [field]: value }));
  };

  const handleUpdateProfile = async (updatedData) => {
    console.log("Requesting database update");
    try {
      setEditedProfile(updatedData);
      await updateProfile.mutateAsync({
        user_id: session.user.id,
        updatedProfile: updatedData,
      });
      toast.success("Profile updated successfully");

      if (newImageFile) {
        const formData = new FormData();
        formData.append("image", newImageFile);
        formData.append("user_id", session.user.id);

        await axios.post(
          "https://hook.eu1.make.com/ra9cha2u8wd9xwz8bso28ao4sfmxuvn9",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success("Profile photo uploaded successfully");
        setNewImageFile(null);
        setNewImagePreview(null);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="overflow-auto relative h-full">
      <Button
        variant="ghost"
        size="icon"
        onClick={toggleEdit}
        className="absolute top-0 right-0 z-10"
      >
        {isEditing ? (
          <Check className="h-4 w-4" />
        ) : (
          <Pencil className="h-4 w-4" />
        )}
      </Button>
      <ProfileHeader
        profile={isEditing ? editedProfile : profile}
        isEditing={isEditing}
        onInputChange={handleInputChange}
        newImagePreview={newImagePreview}
      >
        {isEditing && (
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center rounded-full">
            <label htmlFor="avatar-upload" className="cursor-pointer">
              <Replace className="h-8 w-8 text-white" />
            </label>
            <input
              id="avatar-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageUpload}
            />
          </div>
        )}
      </ProfileHeader>
      <ProfileInfo
        industry={isEditing ? editedProfile.industry : profile.industry}
        location={isEditing ? editedProfile.location : profile.location}
        isEditing={isEditing}
        onInputChange={handleInputChange}
      />
      <AnimatePresence mode="wait">
        <motion.div
          key={isEditing ? "edit" : "view"}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.3 }}
          className="mt-6 space-y-6"
        >
          {isEditing ? (
            <>
              {/* <div className="space-y-2">
                <label htmlFor="communication-channels" className="block text-sm font-medium text-gray-700">
                  Communication Channels
                </label>
                <CreateableCombobox />
              </div> */}
              <ProfileLists
                profileData={editedProfile}
                isEditing={isEditing}
                onUpdateProfile={handleUpdateProfile}
              />
            </>
          ) : (
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">Key Skills</h3>
                <ContractibleList items={profile.key_skills} />
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Business Goals</h3>
                <ContractibleList items={profile.business_goals} />
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Interests</h3>
                <ContractibleList items={profile.interests} />
              </div>
            </div>
          )}
          {/* {!isEditing && (
            <PreferredCommunication
              communication={profile.preferred_communication}
            />
          )} */}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ProfileCard;