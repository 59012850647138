import React from "react";
import { MapPin } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import MatchScore from "../atoms/MatchScore";

const MatchCardHeader = ({ matched_user, matchScore }) => {
  const { name, location, career_stage, image_url } = matched_user;

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <div className="flex items-center mb-4 sm:mb-0">
        <Avatar className="h-12 w-12 sm:h-16 sm:w-16 z-40">
          <AvatarImage src={image_url} alt={name} />
          <AvatarFallback>{name.charAt(0)}</AvatarFallback>
        </Avatar>
        <div className="ml-4">
          <div className="flex flex-col sm:flex-row sm:items-center">
            <h3 className="text-lg sm:text-xl font-semibold">{name}</h3>
            {location && (
              <div className="flex items-center mt-1 sm:mt-0 sm:ml-2 text-sm text-gray-600">
                <MapPin className="w-4 h-4 mr-1" />
                <span>{location}</span>
              </div>
            )}
          </div>
          <p className="text-sm text-gray-600 mt-1">{career_stage}</p>
        </div>
      </div>
      <MatchScore score={matchScore} />
    </div>
  );
};

export default MatchCardHeader;