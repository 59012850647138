import { useState, useEffect } from "react";
import { supabase } from "../supabase";

export const useHostStatus = (session) => {
  const [isHost, setIsHost] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkHostStatus = async () => {
      if (!session?.user?.id) {
        setIsHost(false);
        setIsAdmin(false);
        return;
      }

      const { data, error } = await supabase
        .from("hosts")
        .select("user_id, status")
        .eq("user_id", session.user.id)
        .single();

      if (data && !error) {
        setIsHost(true);
        setIsAdmin(data.status === "admin");
      } else {
        setIsHost(false);
        setIsAdmin(false);
      }
    };

    checkHostStatus();
  }, [session]);

  return { isHost, isAdmin };
};
