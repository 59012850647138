import React from "react";
import { motion } from "framer-motion";
import MatchCardHeader from "../molecules/MatchCardHeader";
import ExpandedSection from "../molecules/ExpandedSection";
import ExternalLinks from "../molecules/ExternalLinks";

const MatchCard = ({
  matched_user,
  matchScore = 0,
  matchReason = "",
  potentialCollaboration = "",
  complimentarySkills = [],
  sharedInterests = [],
  communicationCompatibility = "",
  geographicalSynergy = "",
  isExpanded = false,
  isTransitioning = false,
  onToggle = () => {},
  isBlurred = false,
  isFirstMatch = false,
}) => {
  const baseCardClasses = `bg-white rounded-lg shadow-md cursor-pointer transition-all duration-300 ${
    isExpanded ? "bg-gray-50" : ""
  } ${isBlurred ? "blur-sm" : ""} ${
    isFirstMatch && isBlurred ? "metallic-gold" : ""
  }`;

  return (
    <div className={baseCardClasses} onClick={onToggle}>
      <div className="p-4 sm:p-6">
        <MatchCardHeader matched_user={matched_user} matchScore={matchScore} />
        <motion.div
          className="overflow-visible"
          initial={false}
          animate={{
            height: isExpanded ? "auto" : 0,
            opacity: isExpanded ? 1 : 0,
          }}
          transition={{ duration: 0.3 }}
        >
          {isExpanded && (
            <div
              className={`mt-4 pt-4 border-t ${
                isTransitioning ? "opacity-0" : "opacity-100"
              } transition-opacity duration-300`}
            >
              <ExpandedSection
                matchReason={matchReason}
                potentialCollaboration={potentialCollaboration}
                complimentarySkills={complimentarySkills}
                sharedInterests={sharedInterests}
                communicationCompatibility={communicationCompatibility}
                geographicalSynergy={geographicalSynergy}
              />
              <div className="mt-6"> {/* Added top margin here */}
                <ExternalLinks
                  linkedinUrl={matched_user.link_linkedin}
                  memberProfileUrl={matched_user.link_aaa}
                />
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default MatchCard;