import React, { createContext, useContext, useState, useEffect } from "react";
import { useMeetings } from "../integrations/supabase";
import { useSupabaseAuth } from "../integrations/supabase/auth";
import { format, parseISO } from "date-fns";
import { useHostStatus } from "../integrations/supabase/hooks/useHostStatus";

const MeetingsContext = createContext();

export const formatDate = (dateString) => {
  return dateString
    ? format(parseISO(dateString), "MMMM d, yyyy 'at' h:mm a")
    : "Not given";
};

export const MeetingsProvider = ({ children }) => {
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMeeting, setCurrentMeeting] = useState(null);

  const { session } = useSupabaseAuth();
  const { useGetMeetingsAsHost } = useMeetings();
  const { isAdmin } = useHostStatus(session);

  const {
    data: scheduledData,
    isLoading: scheduledLoading,
    error: scheduledError,
  } = useGetMeetingsAsHost(session?.user?.id, "scheduled", isAdmin);
  const {
    data: completedData,
    isLoading: completedLoading,
    error: completedError,
  } = useGetMeetingsAsHost(session?.user?.id, "done", isAdmin);

  useEffect(() => {
    if (scheduledData && completedData) {
      setScheduledMeetings(scheduledData);
      setCompletedMeetings(completedData);
      setIsLoading(false);

      // Set the first scheduled meeting as the current meeting, or the first completed meeting if no scheduled meetings
      const closestMeeting = scheduledData[0] || completedData[0];
      setCurrentMeeting(closestMeeting);
    }
  }, [scheduledData, completedData]);

  useEffect(() => {
    if (scheduledError || completedError) {
      setError(scheduledError || completedError);
      setIsLoading(false);
    }
  }, [scheduledError, completedError]);

  const switchCurrentMeeting = (meetingId) => {
    const newCurrentMeeting = [...scheduledMeetings, ...completedMeetings].find(
      (meeting) => meeting.meeting_id === meetingId
    );
    if (newCurrentMeeting) {
      console.log("newCurrentMeeting", newCurrentMeeting);
      setCurrentMeeting(newCurrentMeeting);
    }
  };

  const value = {
    scheduledMeetings,
    completedMeetings,
    isLoading,
    error,
    currentMeeting,
    switchCurrentMeeting,
  };

  return (
    <MeetingsContext.Provider value={value}>
      {children}
    </MeetingsContext.Provider>
  );
};

export const useMeetingsContext = () => {
  const context = useContext(MeetingsContext);
  if (!context) {
    throw new Error(
      "useMeetingsContext must be used within a MeetingsProvider"
    );
  }
  return context;
};