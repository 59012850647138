import React, { useState, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import ListItem from "../atoms/ListItem";
import AddItemRow from "./AddItemRow";
import SectionHeader from "../atoms/SectionHeader";

const EditableList = React.memo(({ items, isEditing, onUpdate, title }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  const handleEditItem = useCallback((index) => {
    setEditingIndex(index);
  }, []);

  const handleApproveEdit = useCallback(
    (index, value) => {
      const updatedItems = [...items];
      updatedItems[index] = value;
      onUpdate(updatedItems);
      setEditingIndex(null);
    },
    [items, onUpdate]
  );

  const handleCancelEdit = useCallback(() => {
    setEditingIndex(null);
  }, []);

  const handleSelectItem = useCallback((index) => {
    setSelectedItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  }, []);

  const handleDeleteSelected = useCallback(() => {
    const updatedItems = items.filter(
      (_, index) => !selectedItems.includes(index)
    );
    onUpdate(updatedItems);
    setSelectedItems([]);
  }, [items, selectedItems, onUpdate]);

  const handleAddItem = useCallback(() => {
    setIsAdding(true);
  }, []);

  const handleCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const handleApproveAdd = useCallback(
    (newItem) => {
      if (newItem.trim()) {
        const updatedItems = [newItem.trim(), ...items];
        onUpdate(updatedItems);
      }
      setIsAdding(false);
    },
    [items, onUpdate]
  );

  const memoizedItems = useMemo(
    () =>
      items.map((item, index) => (
        <ListItem
          key={index}
          item={item}
          index={index}
          isEditing={editingIndex === index}
          isSelected={selectedItems.includes(index)}
          onSelect={() => handleSelectItem(index)}
          onEdit={() => handleEditItem(index)}
          onApprove={(value) => handleApproveEdit(index, value)}
          onCancel={handleCancelEdit}
        />
      )),
    [
      items,
      editingIndex,
      selectedItems,
      handleSelectItem,
      handleEditItem,
      handleApproveEdit,
      handleCancelEdit,
    ]
  );

  return (
    <div className="relative">
      <SectionHeader
        title={title}
        onAddClick={handleAddItem}
        onDeleteClick={handleDeleteSelected}
        isEditing={isEditing}
        hasSelectedItems={selectedItems.length > 0}
      />
      <div className="overflow-auto max-h-[300px]">
        <Table>
          <TableHeader className="sticky top-0 bg-white z-10">
            <TableRow>
              <TableHead className="w-12">Select</TableHead>
              <TableHead>Item</TableHead>
              <TableHead className="w-24">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isAdding && (
              <AddItemRow
                onCancel={handleCancelAdd}
                onApprove={handleApproveAdd}
              />
            )}
            {memoizedItems}
          </TableBody>
        </Table>
      </div>
    </div>
  );
});

export default EditableList;
