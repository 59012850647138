import React from "react";
import EditableList from "./EditableList";

const ProfileLists = ({ profileData, isEditing, onUpdateProfile }) => {
  const handleListUpdate = (section, updatedItems) => {
    onUpdateProfile({ ...profileData, [section]: updatedItems });
  };

  return (
    <div className="space-y-6">
      <EditableList
        title="Key Skills"
        items={profileData.key_skills}
        isEditing={isEditing}
        onUpdate={(updatedItems) =>
          handleListUpdate("key_skills", updatedItems)
        }
      />
      <EditableList
        title="Business Goals"
        items={profileData.business_goals}
        isEditing={isEditing}
        onUpdate={(updatedItems) =>
          handleListUpdate("business_goals", updatedItems)
        }
      />
      <EditableList
        title="Interests"
        items={profileData.interests}
        isEditing={isEditing}
        onUpdate={(updatedItems) => handleListUpdate("interests", updatedItems)}
      />
    </div>
  );
};

export default ProfileLists;
