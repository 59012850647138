import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";

export const useMeetings = () => {
  const queryClient = useQueryClient();

  const getMeeting = async (userId) => {
    const { data, error } = await supabase
      .from("meetings")
      .select("*, host:hosts(name), feedback(content, rating)")
      .eq("guest_user_id", userId);
    if (error) throw error;
    return data;
  };

  const getMeetingsAsHost = async (userId, status, isAdmin) => {
    let query = supabase
      .from("meetings")
      .select(`
        *,
        guest:profiles!guest_user_id(
          name, 
          image_url, 
          interests, 
          hobbies, 
          key_skills, 
          business_goals,
          location,
          career_stage,
          created_at
        ),
        host:hosts!host_user_id(name),
        feedback(content, rating)
      `)
      .eq("meeting_status", status)
      .order("event_start_time", { ascending: false });

    if (!isAdmin) {
      query = query.eq("host_user_id", userId);
    }

    const { data, error } = await query;
    if (error) throw error;
    return data;
  };

  const addMeeting = async (newMeeting) => {
    const { data, error } = await supabase
      .from("meetings")
      .insert([newMeeting]);
    if (error) throw error;
    return data;
  };

  const updateMeeting = async ({ meeting_id, updatedMeeting }) => {
    const { data, error } = await supabase
      .from("meetings")
      .update(updatedMeeting)
      .eq("meeting_id", meeting_id);
    if (error) throw error;
    return data;
  };

  const deleteMeeting = async (meeting_id) => {
    const { data, error } = await supabase
      .from("meetings")
      .delete()
      .eq("meeting_id", meeting_id);
    if (error) throw error;
    return data;
  };

  const getHostInfo = async (meetingId) => {
    const { data, error } = await supabase
      .from("host_info")
      .select("*")
      .eq("meeting_id", meetingId)
      .single();
    if (error) {
      if (error.code === "PGRST116") {
        // No rows returned, return null instead of throwing an error
        return null;
      }
      throw error;
    }
    return data;
  };

  const getHostFeedback = async (meetingId, hostUserId) => {
    const { data, error } = await supabase
      .from("feedback")
      .select("content")
      .eq("meeting_id", meetingId)
      .eq("user_id", hostUserId)
      .single();
    if (error) {
      if (error.code === "PGRST116") {
        // No rows returned, return null instead of throwing an error
        return null;
      }
      throw error;
    }
    return data?.content || null;
  };

  return {
    useGetMeetings: (userId) =>
      useQuery({
        queryKey: ["meetings", userId],
        queryFn: () => getMeeting(userId),
        enabled: !!userId,
      }),
    useGetMeetingsAsHost: (userId, status, isAdmin) =>
      useQuery({
        queryKey: ["meetingsAsHost", userId, status, isAdmin],
        queryFn: () => getMeetingsAsHost(userId, status, isAdmin),
        enabled: !!userId && !!status,
      }),
    useAddMeeting: () =>
      useMutation({
        mutationFn: addMeeting,
        onSuccess: () => queryClient.invalidateQueries(["meetings"]),
      }),
    useUpdateMeeting: () =>
      useMutation({
        mutationFn: updateMeeting,
        onSuccess: () => queryClient.invalidateQueries(["meetings"]),
      }),
    useDeleteMeeting: () =>
      useMutation({
        mutationFn: deleteMeeting,
        onSuccess: () => queryClient.invalidateQueries(["meetings"]),
      }),
    useGetHostInfo: (meetingId) =>
      useQuery({
        queryKey: ["hostInfo", meetingId],
        queryFn: () => getHostInfo(meetingId),
        enabled: !!meetingId,
        retry: (failureCount, error) => {
          // Don't retry if no rows were returned
          if (error && error.message === "No rows returned") {
            return false;
          }
          // Default retry behavior for other errors
          return failureCount < 3;
        },
      }),
    useGetHostFeedback: (meetingId, hostUserId) =>
      useQuery({
        queryKey: ["hostFeedback", meetingId, hostUserId],
        queryFn: () => getHostFeedback(meetingId, hostUserId),
        enabled: !!meetingId && !!hostUserId,
        retry: (failureCount, error) => {
          // Don't retry if no rows were returned
          if (error && error.message === "No rows returned") {
            return false;
          }
          // Default retry behavior for other errors
          return failureCount < 3;
        },
      }),
  };
};
