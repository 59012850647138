import React, { useState } from "react";
import StatItem from "../atoms/StatItem";
import {
  Phone,
  Clock,
  UserMinus,
  MessageSquare,
  Calendar,
  CheckSquare,
} from "lucide-react";
import { useMeetingsContext } from "../../contexts/MeetingsContext";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { useFeedback } from "../../integrations/supabase";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { motion, AnimatePresence } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader2 } from "lucide-react";
import { useHostStatus } from "../../integrations/supabase/hooks/useHostStatus";

const HostListItem = ({ name, imageUrl, isActive, onClick, hostName, isAdmin }) => {
  const firstName = name ? name.split(" ")[0] : "Unknown";
  return (
    <div
      className={`flex items-center space-x-2 p-2 ${
        isActive ? "text-purple-600 bg-purple-100" : "text-black"
      } cursor-pointer hover:bg-gray-100 w-full`}
      onClick={onClick}
    >
      <Avatar className="w-8 h-8 flex-shrink-0">
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback>{firstName.charAt(0)}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <span className="truncate">{firstName}</span>
        {isAdmin && hostName && (
          <span className="text-xs text-gray-400 truncate">{hostName}</span>
        )}
      </div>
    </div>
  );
};

const NoMeetingsMessage = ({ icon: Icon, message }) => (
  <div className="flex flex-col items-center justify-center h-full text-gray-500">
    <Icon className="w-12 h-12 mb-4" />
    <p className="text-center text-sm">{message}</p>
  </div>
);

const MeetingsList = ({
  meetings,
  currentMeeting,
  switchCurrentMeeting,
  icon,
  emptyMessage,
  isAdmin,
}) => {
  const [displayedMeetings, setDisplayedMeetings] = useState(5);

  const loadMoreMeetings = () => {
    setDisplayedMeetings((prevCount) => prevCount + 5);
  };

  if (meetings.length === 0) {
    return <NoMeetingsMessage icon={icon} message={emptyMessage} />;
  }

  return (
    <InfiniteScroll
      dataLength={meetings.slice(0, displayedMeetings).length}
      next={loadMoreMeetings}
      hasMore={displayedMeetings < meetings.length}
      loader={<Loader2 className="w-4 h-4 animate-spin" />}
      scrollableTarget="meetingsContainer"
    >
      {meetings.slice(0, displayedMeetings).map((meeting) => (
        <HostListItem
          key={meeting.meeting_id}
          name={meeting.guest?.name || "Unknown"}
          imageUrl={meeting.guest?.image_url}
          isActive={meeting.meeting_id === currentMeeting?.meeting_id}
          onClick={() => switchCurrentMeeting(meeting.meeting_id)}
          hostName={meeting.host?.name || "Unknown Host"}
          isAdmin={isAdmin}
        />
      ))}
    </InfiniteScroll>
  );
};

const HostSidebar = () => {
  const {
    scheduledMeetings,
    completedMeetings,
    isLoading,
    error,
    currentMeeting,
    switchCurrentMeeting,
  } = useMeetingsContext();
  const { session } = useSupabaseAuth();
  const { useGetFeedbackByUserId } = useFeedback();
  const { data: feedbacks, isLoading: isFeedbackLoading } =
    useGetFeedbackByUserId(session?.user?.id);
  const [showUpcoming, setShowUpcoming] = useState(true);
  const { isHost, isAdmin } = useHostStatus(session);

  if (isLoading || isFeedbackLoading) {
    return <HostSidebarSkeleton />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const calculateAverageDuration = () => {
    const totalDuration = completedMeetings.reduce(
      (sum, meeting) => sum + (meeting.duration || 0),
      0
    );
    return completedMeetings.length > 0
      ? Math.round(totalDuration / completedMeetings.length)
      : 0;
  };

  const noShowsCount = completedMeetings.filter(
    (meeting) => meeting.meeting_status === "no-show"
  ).length;
  const collectedFeedbacksCount = feedbacks ? feedbacks.length : 0;

  return (
    <div className="bg-white rounded-lg h-full p-6">
      <h2 className="text-3xl font-bold text-blue-500 mb-6">
        {isAdmin ? "All Guests" : "Your Guests"}
      </h2>
      <div className="flex space-x-2 mb-4">
        <Button
          onClick={() => setShowUpcoming(true)}
          variant={showUpcoming ? "default" : "outline"}
          className="w-1/2 p-2"
        >
          Upcoming
        </Button>
        <Button
          onClick={() => setShowUpcoming(false)}
          variant={!showUpcoming ? "default" : "outline"}
          className="w-1/2 p-2"
        >
          Completed
        </Button>
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={showUpcoming ? "upcoming" : "completed"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          className="space-y-2 mb-6 bg-gray-50 rounded-lg p-4 shadow-inner shadow-gray-200 h-64 overflow-y-auto"
          id="meetingsContainer"
        >
          <MeetingsList
            meetings={showUpcoming ? scheduledMeetings : completedMeetings}
            currentMeeting={currentMeeting}
            switchCurrentMeeting={switchCurrentMeeting}
            icon={showUpcoming ? Calendar : CheckSquare}
            emptyMessage={
              showUpcoming
                ? "No upcoming meetings scheduled."
                : "No completed meetings yet."
            }
            isAdmin={isAdmin}
          />
        </motion.div>
      </AnimatePresence>
      <div className="space-y-4 bg-gray-50 rounded-lg p-4 shadow-inner shadow-gray-200">
        <StatItem
          icon={Phone}
          label="Completed Calls"
          value={completedMeetings.length}
        />
        <StatItem
          icon={Clock}
          label="Avg. Call Duration"
          value={`${calculateAverageDuration()} min`}
        />
        <StatItem icon={UserMinus} label="No-Shows" value={noShowsCount} />
        <StatItem
          icon={MessageSquare}
          label="Collected Feedbacks"
          value={collectedFeedbacksCount}
        />
      </div>
    </div>
  );
};

const HostSidebarSkeleton = () => (
  <div className="bg-white rounded-lg h-full p-6 space-y-6">
    <Skeleton className="h-8 w-3/4" />
    <div className="space-y-2">
      <Skeleton className="h-6 w-1/2" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
    </div>
    <div className="space-y-2">
      <Skeleton className="h-6 w-1/2" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
    </div>
    <div className="space-y-4">
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-6 w-full" />
    </div>
  </div>
);

export default HostSidebar;