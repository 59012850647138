import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import { Menu, X, Shield, Map, ToggleLeft, LogOut } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import Logo from "../atoms/Logo";
import NavButtonGroup from "../molecules/NavButtonGroup";
import { supabase } from "../../integrations/supabase/supabase";
import { useHostStatus } from "../../integrations/supabase/hooks/useHostStatus";
import MapPopup from "./MapPopup";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, logout } = useSupabaseAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isHost, isAdmin } = useHostStatus(session);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isMapActive, setIsMapActive] = useState(false);

  const handleSignOut = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleMap = () => {
    setIsMapOpen(!isMapOpen);
    setIsMapActive(!isMapActive);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Logo onClick={() => setIsPopupOpen(true)} />
          </div>
          <div className="hidden md:flex md:items-center space-x-4">
            {session && (
              <>
                {isAdmin && (
                  <Shield className="w-5 h-5 text-purple-600" />
                )}
                <button
                  onClick={toggleMap}
                  className={`p-2 rounded-full transition-colors ${
                    isMapActive ? 'bg-purple-100 text-purple-600' : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <Map className="w-5 h-5" />
                </button>
                <NavButtonGroup onSignOut={handleSignOut} isHost={isHost} />
              </>
            )}
          </div>
          <div className="md:hidden flex items-center">
            {session && (
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-full text-purple-600 hover:text-purple-700 hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      {session && (
        <MobileMenu
          isOpen={isMenuOpen}
          onClose={toggleMenu}
          onSignOut={handleSignOut}
          isHost={isHost}
          isAdmin={isAdmin}
          onToggleMap={toggleMap}
          isMapActive={isMapActive}
        />
      )}
      <Dialog open={isPopupOpen} onOpenChange={setIsPopupOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold mb-4">
              Welcome to the AAA Matchmaker beta!
            </DialogTitle>
          </DialogHeader>
          <DialogDescription className="text-base leading-relaxed">
            <p className="mb-4">
              This exclusive case study for our AAA Accelerator community is the
              foundation for 'voiceloop', our upcoming SaaS platform for
              AI-powered community services.
            </p>
            <p className="mb-4">
              Your feedback is crucial in shaping this tool. Please click 'Give
              Feedback' to share your thoughts and help us meet your needs.
            </p>
            <p className="mb-4">
              Together, we're building something extraordinary. Thank you for
              being part of this journey!
            </p>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      {session && <MapPopup isOpen={isMapOpen} onClose={toggleMap} />}
    </nav>
  );
};

const MobileMenu = ({ isOpen, onClose, onSignOut, isHost, isAdmin, onToggleMap, isMapActive }) => {
  const location = useLocation();
  const isHostsRoute = location.pathname === '/hosts';

  if (!isOpen) return null;

  return (
    <div className="md:hidden bg-white border-t border-gray-200">
      <div className="px-2 pt-2 pb-3 space-y-1">
        {isAdmin && (
          <div className="flex items-center space-x-2 px-3 py-2 rounded-md text-base font-medium text-gray-700">
            <Shield className="w-5 h-5 text-purple-600" />
            <span>Admin</span>
          </div>
        )}
        <button
          onClick={onToggleMap}
          className={`flex items-center space-x-2 w-full px-3 py-2 rounded-md text-base font-medium ${
            isMapActive ? 'bg-purple-100 text-purple-600' : 'text-gray-700 hover:bg-gray-100'
          }`}
        >
          <Map className="w-5 h-5" />
          <span>Map</span>
        </button>
        {isHost && (
          <Link
            to={isHostsRoute ? "/" : "/hosts"}
            className="flex items-center space-x-2 px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
            onClick={onClose}
          >
            <ToggleLeft className="w-5 h-5" />
            <span>{isHostsRoute ? "Matches" : "Hosts"}</span>
          </Link>
        )}
        <button
          onClick={() => {
            onSignOut();
            onClose();
          }}
          className="flex items-center space-x-2 w-full px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
        >
          <LogOut className="w-5 h-5" />
          <span>Sign Out</span>
        </button>
      </div>
    </div>
  );
};

export default Navbar;