import React from "react";
import { Navigate } from "react-router-dom";
import { useSupabaseAuth } from "../integrations/supabase/auth";
import logo from "/public/logo_voiceloop.png";

const ProtectedRoute = ({ children }) => {
  const { session, loading } = useSupabaseAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <img src={logo} alt="Loading" className="h-12 w-12" />
      </div>
    );
  }

  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
