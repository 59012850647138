import React from "react";
import { Button } from "@/components/ui/button";
import { Plus, Trash2 } from "lucide-react";

const SectionHeader = React.memo(
  ({ title, onAddClick, onDeleteClick, isEditing, hasSelectedItems }) => {
    return (
      <div className="flex items-end justify-between mb-1">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        {isEditing && (
          <div className="flex space-x-2">
            <Button
              onClick={onDeleteClick}
              variant="ghost"
              size="sm"
              className="p-0 h-6 w-6"
              disabled={!hasSelectedItems}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
            <Button
              onClick={onAddClick}
              variant="ghost"
              size="sm"
              className="p-0 h-6 w-6"
            >
              <Plus className="h-4 w-4" />
            </Button>
          </div>
        )}
      </div>
    );
  }
);

export default SectionHeader;
