import React from "react";
import { ExternalLink } from "lucide-react";

const ExternalLinkButton = ({ text, color, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={`${color} text-white px-3 py-2 rounded flex items-center justify-center w-full text-sm ${
      url ? "" : "opacity-50 cursor-not-allowed"
    }`}
    onClick={(e) => {
      if (!url) {
        e.preventDefault();
        console.log(`${text} URL is not available`);
      }
    }}
  >
    {text} <ExternalLink className="ml-2 h-4 w-4" />
  </a>
);

export default ExternalLinkButton;