import { useState, useEffect } from "react";
import MatchCard from "./MatchCard";

const MatchList = ({ matches, shouldBlurFirstMatch, onBlurredMatchClick }) => {
  const [expandedMatchIndex, setExpandedMatchIndex] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleExpand = (index) => {
    if (expandedMatchIndex === index) return;
    setIsTransitioning(true);
    setExpandedMatchIndex(null);
    setTimeout(() => {
      setExpandedMatchIndex(index);
      setIsTransitioning(false);
    }, 300);
  };

  useEffect(() => {
    if (matches && matches.length > 0 && !shouldBlurFirstMatch) {
      setExpandedMatchIndex(0);
    }
  }, [matches, shouldBlurFirstMatch]);

  const handleCardClick = (index) => {
    if (index === 0 && shouldBlurFirstMatch) {
      onBlurredMatchClick();
    } else {
      handleExpand(index);
    }
  };

  if (!matches || matches.length === 0) {
    return <p>No matches available.</p>;
  }

  return (
    <div className="space-y-4">
      {matches.map((match, index) => (
        <MatchCard
          key={index}
          {...match}
          isExpanded={index === expandedMatchIndex}
          isTransitioning={isTransitioning}
          onToggle={() => handleCardClick(index)}
          matchReason={match.explanation || "Match reason not provided"}
          potentialCollaboration={
            match.potential_collaboration ||
            "Potential collaboration not specified"
          }
          complimentarySkills={match.complementary_skills || []}
          sharedInterests={match.shared_interests || []}
          communicationCompatibility={
            match.communication_compatibility ||
            "Communication compatibility not specified"
          }
          geographicalSynergy={
            match.geographical_synergy || "Geographical synergy not provided"
          }
          matched_user={{
            name: match.matched_user?.name || "Unknown",
            location: match.matched_user?.location || "",
            career_stage: match.matched_user?.career_stage || "",
            image_url: match.matched_user?.image_url || "",
            link_linkedin: match.matched_user?.link_linkedin || "",
            link_aaa: match.matched_user?.link_aaa || "",
          }}
          matchScore={match.matching_score || 0}
          isBlurred={index === 0 && shouldBlurFirstMatch}
          isFirstMatch={index === 0}
        />
      ))}
    </div>
  );
};

export default MatchList;