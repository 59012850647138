import React, { Suspense, useEffect, useState } from "react";
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Layout from "./components/templates/Layout";
import Index from "./pages/Index";
import Meetings from "./pages/Meetings";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Hosts from "./pages/Hosts";
import ProtectedRoute from "./components/ProtectedRoute";
import { useSupabaseAuth } from "./integrations/supabase/auth";
import { MeetingProvider } from "./contexts/MeetingContext";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import Cookies from "js-cookie";
import Navbar from "./components/organisms/Navbar";

const OnboardingPopup = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px] z-50">
        <div className="aspect-video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/xXFtce4jYtI"
            title="Onboarding Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </DialogContent>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm z-40"
          onClick={onClose}
        />
      )}
    </Dialog>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Routes location={location}>
          <Route path="/login" element={<Login />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout>
                  <Index />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/meetings"
            element={
              <ProtectedRoute>
                <Layout>
                  <Meetings />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/hosts"
            element={
              <ProtectedRoute>
                <Layout>
                  <Hosts />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

const App = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { session } = useSupabaseAuth();
  const location = useLocation();

  useEffect(() => {
    const hasWatchedOnboarding = Cookies.get("watched_onboarding") === "true";
    const userEmail = session?.user?.email;
    const excludedEmails = [
      "admin@libralab.ai",
      "marius.santiago.wilsch@gmail.com",
    ];

    if (
      !hasWatchedOnboarding &&
      userEmail &&
      !excludedEmails.includes(userEmail) &&
      location.pathname !== "/reset_password" //? What about /login?
    ) {
      setShowOnboarding(true);
    }
  }, [session, location.pathname]);

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
    Cookies.set("watched_onboarding", "true", { expires: 365 });
  };

  return (
    <MeetingProvider>
      <TooltipProvider>
        <Toaster position="top-right" />
        <Navbar />
        <AnimatedRoutes />
        <OnboardingPopup
          isOpen={showOnboarding}
          onClose={handleCloseOnboarding}
        />
      </TooltipProvider>
    </MeetingProvider>
  );
};

export default App;
