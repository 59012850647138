import { useState, useEffect } from 'react';

export const useLastCompletedMeetingFeedback = (
  feedbacks,
  feedbacksLoading,
  feedbacksError,
  guestMeetings,
  guestMeetingsLoading,
  guestMeetingsError
) => {
  const [shouldBlurFirstMatch, setShouldBlurFirstMatch] = useState(false);
  const [lastCompletedMeetingId, setLastCompletedMeetingId] = useState(null);

  useEffect(() => {
    if (
      feedbacksLoading ||
      feedbacksError ||
      guestMeetingsLoading ||
      guestMeetingsError ||
      feedbacks === undefined
    ) {
      return;
    }

    const completedMeetings =
      guestMeetings?.filter((meeting) => meeting.meeting_status === "done") ||
      [];

    if (completedMeetings.length === 0) {
      setShouldBlurFirstMatch(false);
      return;
    }

    const lastCompletedMeeting = completedMeetings[0];
    setLastCompletedMeetingId(lastCompletedMeeting.meeting_id);

    // Check if there's feedback for the last completed meeting
    const hasFeedbackForLastMeeting = feedbacks.some(
      (feedback) => feedback.meeting_id === lastCompletedMeeting.meeting_id
    );

    setShouldBlurFirstMatch(!hasFeedbackForLastMeeting);
  }, [
    feedbacksLoading,
    feedbacksError,
    feedbacks,
    guestMeetings,
    guestMeetingsLoading,
    guestMeetingsError,
  ]);

  return { shouldBlurFirstMatch, lastCompletedMeetingId };
};